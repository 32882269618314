<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <v-card elevation="0" class="v-card-margin-bottom-30 py-1">
      <v-card-title
        class="body-1"
        v-if="navigete != null && navigete != '' && navigete != 'null'"
      >
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        เติมเงิน
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon
        ><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
            <h5>วันที่ :</h5>
          </v-col>
          <v-col cols="12" md="2" lg="2" class="pb-3">
            <v-menu
              ref="search_menu_date_from"
              v-model="search_menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search_date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  label="เริ่ม"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_from"
                @input="
                  (search_menu_date_from = false),
                    $refs.search_menu_date_from.save(search_date_from)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" class="pb-2">
            <v-menu
              ref="search_menu_date_to"
              v-model="search_menu_date_to"
              :close-on-content-click="false"
              :return-value.sync="search_date_to"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_to"
                  @change="(value) => (computed_search_date_to = value)"
                  autocomplete="off"
                  label="สิ้นสุด"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_to"
                @input="
                  (search_menu_date_to = false),
                    $refs.search_menu_date_to.save(search_date_to)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn dark color="primary" @click="search_topup_history()" class="mr-3"
              >ค้นหา</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-icon size="20" color="success">fa-receipt</v-icon
        ><span class="my-3 ml-3">ประวัติการแจ้งชำระเงิน</span>
        <v-spacer />
        <v-btn
          class="mr-2"
          color="success"
          outlined
          v-show="showdataTable.length > 0"
          @click="export_to_excel()"
          >export to excel</v-btn
        >

        <v-btn
          class="hidden-sm-and-up"
          @click="open_dialog_topup(true)"
          dark
          color="primary"
          ><v-icon left>mdi-plus</v-icon> แจ้งชำระเงิน</v-btn
        >
        <v-btn
          class="hidden-xs-only"
          @click="open_dialog_topup(false)"
          dark
          color="primary"
          ><v-icon left>mdi-plus</v-icon> แจ้งชำระเงิน</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row class="hidden-xs-only">
          <v-col cols="12" v-if="this.showdataTable.length != 0">
            <v-data-table
              :headers="headers"
              :items="showdataTable"
              class="elevation-0 packhai-border-table"
              hide-default-footer
              :items-per-page="10000"
            >
              <template v-slot:item.id="{ item }">
                {{ FormatTopup(item.id) }}
              </template>
              <template v-slot:item.accountName="{ item }">
                <div class="my-2">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.logo" />
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span
                  ><br />
                  <span>{{ item.accountNumber }}</span
                  ><br />
                  <span>{{ item.accountName }}</span>
                </div>
              </template>
              <template v-slot:item.fileUrl="{ item }">
                <v-img
                  style="margin: 5px"
                  v-if="item.fileUrl != null"
                  :src="item.fileUrl"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="50"
                  max-height="50"
                  @click="image_click(item.fileUrl)"
                ></v-img>
              </template>
              <template v-slot:item.amount="{ item }">
                {{ format_price(item.amount) }}
              </template>
              <template v-slot:item.transferDate="{ item }">
                {{ set_format_date_time(item.transferDate) }}
              </template>
              <template v-slot:item.approveDateTime="{ item }">
                <div v-if="item.approveDateTime != null">
                  <span
                    >ตรวจสอบวันที่
                    {{ " " + set_format_date_time(item.approveDateTime) + " " }}
                  </span>
                </div>
                <div v-else-if="item.approveDateTime == null && item.RejectBy != null">
                  <span
                    >ปฏิเสธวันที่
                    {{ " " + set_format_date_time(item.rejectDateTime) + " " }}
                  </span>
                </div>
              </template>

              <template v-slot:item.statusDecription="{ item }">
                <v-row>
                  <v-col cols="12" align="left" style="margin-left: 15px">
                    <span v-if="item.status == 3"
                      ><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon>
                      {{ item.statusDecription }}</span
                    >
                    <span v-else-if="item.status == 1"
                      ><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon>
                      {{ item.statusDecription }}</span
                    >
                    <span v-else-if="item.status == 2"
                      ><v-icon size="10" color="success"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                      {{ item.statusDecription }}</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.status="{ item }">
                <v-btn
                  icon
                  color="danger"
                  @click="detete_topup(item.id)"
                  v-if="item.status == 1"
                  ><v-icon size="18">fa-trash-alt</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
          <v-col v-else align="center">
            <span><b>ไม่มีข้อมูล !!</b></span>
          </v-col>
        </v-row>

        <v-card
          class="hidden-sm-and-up mx-0 mb-2"
          style="border-radius: 0px"
          elevation="0"
          v-for="(item, index) in showdataTable"
          :key="index"
        >
          <v-row class="py-2 pa-1 mx-0">
            <v-col cols="8" align="left">
              <span v-if="item.status == 3"
                ><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon>
                ปฎิเสธ</span
              >
              <span v-else-if="item.status == 1"
                ><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon>
                รอตรวจสอบ</span
              >
              <span v-else-if="item.status == 2"
                ><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                ตรวจสอบแล้ว</span
              >
            </v-col>
            <v-col cols="4" align="right" v-if="item.fileUrl != null"
              ><a @click="image_click(item.fileUrl)">ดูสลิป</a></v-col
            >
          </v-row>
          <v-divider />
          <v-row class="py-2 pa-1 mx-0">
            <v-col cols="12">
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="12" align="left">
                  {{ FormatTopup(item.id) }}
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0 py-10" cols="12" align="center">
                  <span
                    ><h1>฿ {{ format_price(item.amount) }}</h1></span
                  >
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left"> ธนาคาร : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.logo" />
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span
                  ><br />
                  <span>{{ item.accountNumber }}</span
                  ><br />
                  <span>{{ item.accountName }}</span>
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left"> วันที่โอน : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ set_format_date_time(item.transferDate) }}
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left"> วันที่แจ้งชำระ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ set_format_date_time(item.createDatetime) }}
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left"> หมายเหตุ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ item.remark }}
                </v-col>
              </v-row>

              <v-row class="py-2 mx-0 px-0" v-if="item.status == 2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ตรวจสอบวันที่ : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ set_format_date_time(item.approveDateTime) }}</span>
                </v-col>
              </v-row>
              <v-row class="py-2 mx-0 px-0" v-if="item.status == 3">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ปฎิเสธวันที่ : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ set_format_date_time(item.rejectDateTime) }}</span>
                </v-col>
              </v-row>

              <v-row class="py-2 mx-0 px-0">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>Reference No : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.referenceNo }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="topup_dialog"
      scrollable
      :max-width="size_percent_for_mobile"
      persistent
    >
      <v-card class="my-0">
        <v-card-title>
          <span>แจ้งโอน</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              (topup_dialog = false),
                clear_data(),
                data_and_time_onload(),
                resetValidForm()
            "
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">ธนาคาร :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-select
                  ref="BranchBankAccountID"
                  :items="bank_list"
                  v-model="BranchBankAccountID"
                  item-key="fullBankName"
                  item-value="id"
                  style="max-width: 500px"
                  hide-details
                  dense
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    <v-col cols="1" class="px-0" v-if="data.item.logo != null">
                      <v-avatar size="25px">
                        <img :src="data.item.logo" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="11" class="mx-0">
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-avatar size="25px" v-if="data.item.logo != null">
                      <img :src="data.item.logo" />
                    </v-avatar>
                    <v-col class="ml-3">
                      {{ data.item.fullBankName }}
                    </v-col>
                  </template>
                </v-select>
                <span
                  v-if="this.BankValid == true && this.BranchBankAccountID == null"
                  style="color: red"
                  >{{ this.BankValidText }}</span
                >
              </v-col>
            </v-row>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">ยอดที่โอน :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-text-field
                  ref="Amount"
                  :value="Amount"
                  @change="(value) => (Amount = value)"
                  autocomplete="off"
                  outlined
                  type="number"
                  :rules="AmountRules"
                  required
                  hide-details
                  dense
                  style="width: 250px"
                ></v-text-field>
                <span
                  v-if="
                    this.AmountValid == true && (this.Amount == null || this.Amount == '')
                  "
                  style="color: red"
                  >{{ this.AmountValidText }}</span
                >
              </v-col>
            </v-row>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">วันที่ :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-menu
                  ref="menu_date_transfer"
                  v-model="menu_date_transfer"
                  :close-on-content-click="false"
                  :return-value.sync="date_transfer"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computed_date_transfer"
                      @change="(value) => (computed_date_transfer = value)"
                      autocomplete="off"
                      prepend-inner-icon="mdi-calendar"
                      ref="dateRangeText"
                      outlined
                      readonly
                      v-on="on"
                      style="width: 250px"
                      hide-details
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_transfer"
                    no-title
                    scrollable
                    :allowed-dates="allowedDates"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_transfer = null"
                      >Reset</v-btn
                    >
                    <v-btn text color="primary" @click="menu_date_transfer = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_date_transfer.save(date_transfer)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">เวลาประมาน :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-menu
                  ref="time_picker"
                  v-model="time_picker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="time"
                      @change="(value) => (time = value)"
                      autocomplete="off"
                      prepend-inner-icon="mdi-clock"
                      outlined
                      readonly
                      style="width: 250px"
                      v-on="on"
                      hide-details
                      dense
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="time_picker"
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.time_picker.save(time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">แนบไฟล์ :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-row row>
                  <v-col class="pb-0" cols="12">
                    <v-btn
                      onclick="document.getElementById('fileInput').click()"
                      outlined
                      color="primary"
                      >แนบไฟล์</v-btn
                    >
                    <v-btn
                      v-if="myFile != null"
                      icon
                      color="danger"
                      @click="myFile = null"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                    <v-file-input
                      id="fileInput"
                      v-model="myFile"
                      accept="image/*"
                      @change="set_data_file()"
                      style="display: none"
                    />
                  </v-col>
                </v-row>
                <v-row row>
                  <v-col class="pb-0" cols="6" md="2">
                    <v-img
                      class="mt-3 title mb-1"
                      v-if="this.imglogoPre != null && this.myFile != null"
                      :src="imglogoPre"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row row class="py-2">
              <v-col class="pb-0" cols="12" md="2">หมายเหตุ :</v-col>
              <v-col class="pb-0" cols="12" md="10">
                <v-textarea
                  ref="Remark"
                  v-model="Remark"
                  outlined
                  name="input-7-4"
                  style="max-width: 500px"
                  hide-details
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-col class="py-1" cols="12" align="right">
            <v-btn color="danger" dark @click="createTopupOrder()">แจ้งชำระเงิน</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="900">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-img :src="dialog_photo_link" aspect-ratio="1.3" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import {
  adminService,
  branchService_dotnet,
  generalService_dotnet,
} from "@/website/global";
import { set_format_date_time } from "@/website/global_function";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  format_number,
  format_price,
  formatMoney,
  isNumberWNoDot,
  FormatTopup,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    navigete: [],

    TopupStatusDict: {
      WaitCheck: 1,
      Checked: 2,
      Rejected: 3,
    },

    topupDialog: false,
    transferMoney: "",
    transferDate: "",
    transferHour: new Date().getHours(),
    transferMin: new Date().getMinutes(),
    showPicker: false,

    search_menu_date_from: false,
    search_date_from: null,
    search_menu_date_to: false,
    search_date_to: null,

    menu_date_transfer: false,
    date_transfer: null,

    // time picker
    time: null,
    time_picker: false,

    shop_id: null,
    Amount: null,
    TransferDate: null,
    BranchBankAccountID: null,
    File_url: null,
    Remark: null,

    // dialog_photo
    dialog_photo: false,
    dialog_photo_link: null,

    // ธนาคาร
    bank_list: [],

    // upload file
    myFile: null,
    myFile_insert: null,
    path_name: null,
    path_name_del: null,
    imglogoPre: null,

    // dialog topup
    topup_dialog: false,
    size_percent_for_mobile: "45%",

    hour: [
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
    ],
    minit: [
      { text: "01", value: 1 },
      { text: "02", value: 2 },
      { text: "03", value: 3 },
    ],

    item1: [
      { text: "test1", value: 1 },
      { text: "test2", value: 2 },
      { text: "test3", value: 3 },
    ],

    headers: [
      {
        text: "เลขที่รายการ",
        value: "id",
        width: "125px",
        align: "center",
        sortable: false,
      },
      {
        text: "ชำระเวลา",
        align: "left",
        sortable: false,
        value: "transferDate",
        width: "170px",
      },
      {
        text: "ช่องทาง",
        align: "left",
        sortable: false,
        value: "accountName",
        width: "300px",
      },
      { text: "สลิป", align: "center", value: "fileUrl", sortable: false },
      {
        text: "ยอด(บาท)",
        align: "right",
        value: "amount",
        sortable: false,
        width: "120px",
      },
      {
        text: "หมายเหตุ",
        align: "left",
        value: "remark",
        sortable: false,
        width: "300px",
      },
      {
        text: "ตรวจสอบ/ปฏิเสธ",
        value: "approveDateTime",
        sortable: false,
        width: "300px",
        align: "left",
      },
      {
        text: "สถานะ",
        align: "center",
        value: "statusDecription",
        sortable: false,
        width: "150px",
      },
      { text: "ลบ", align: "center", value: "status", sortable: false },
    ],
    showdataTable: [],

    // validate
    valid: true,
    AmountRules: [(v) => !!v || "กรุณากรอก ยอดที่โอน"],

    AmountValid: false,
    AmountValidText: false,
    BankValid: false,
    BankValidText: false,
  }),
  async created() {
    this.navigete = JSON.parse(localStorage.getItem("navigete"));
    await this.get_bank_account();
    await this.search_topup_history();

    if (this.bank_list.length != 0) {
      this.BranchBankAccountID = this.bank_list[0]["id"];
    }

    this.data_and_time_onload();
    this.page_loading = false;
  },
  computed: {
    computed_search_date_from() {
      return this.formatDates(this.search_date_from);
    },
    computed_search_date_to() {
      return this.formatDates(this.search_date_to);
    },
    computed_date_transfer() {
      return this.formatDateTransfer(this.date_transfer);
    },
  },
  watch: {},
  methods: {
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    formatDate(date) {
      if (date.length == 0) return null;

      if (date.length == 1) {
        const [year, month, day] = date[0].split("-");
        return `${day}-${month}-${year}`;
      } else {
        const [year_from, month_from, day_from] = date[0].split("-");
        const [year_to, month_to, day_to] = date[1].split("-");
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`;
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`;
        }
      }
    },

    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    formatDateTransfer(date) {
      if (date == null) return null;

      if (date != null) {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    data_and_time_onload() {
      this.date_transfer = new Date().toISOString().substr(0, 10);
      this.time =
        Number(new Date().toISOString().substr(11, 2)) +
        7 +
        ":" +
        new Date().toISOString().substr(14, 2);
    },

    validate() {
      this.$refs.form.validate();
    },

    resetValidForm() {
      this.$refs.form.resetValidation();
      this.AmountValid = false;
      this.AmountValidText = null;
      this.BankValid = false;
      this.BankValidText = null;
    },

    image_click(photo_link) {
      this.dialog_photo_link = photo_link;
      this.dialog_photo = true;
    },

    set_data_file() {
      if (this.myFile != null) {
        if (this.myFile["type"] == "image/png" || this.myFile["type"] == "image/jpeg") {
          var url = URL.createObjectURL(this.myFile);
          this.imglogoPre = url;
          var nowDate = new Date().toISOString();
          var s3_url = "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Branch/";
          this.myFile_insert =
            s3_url +
            "Branch" +
            localStorage.getItem("Branch_BranchID") +
            "_slip_" +
            nowDate;
          this.path_name =
            "Branch" + localStorage.getItem("Branch_BranchID") + "_slip_" + nowDate;
        }
      }
    },

    async get_bank_account() {
      let response = await axios.get(branchService_dotnet + "Topup/get-oasys-bank-list", {
        headers: this.header_token,
      });

      if (response.data.status == "success") {
        if (response.data.bankList.length > 0) {
          var dataTemp = response.data.bankList;
          this.bank_list = [];
          this.bank_list.push({
            id: 0,
            fullBankName: "--- กรุณาเลือกธนาคาร ---",
            logo: null,
          });
          dataTemp.forEach((element) => {
            this.bank_list.push({
              id: element.id,
              fullBankName:
                element.bankName +
                " " +
                element.accountNumber +
                " " +
                element.accountName,
              logo: element.logo,
            });
          });
        }
      }
    },

    async search_topup_history() {
      this.loading = true;
      if (this.search_date_from != null && this.search_date_to != null) {
        this.search_date_range = [this.search_date_from, this.search_date_to];
      } else {
        this.search_date_range = [];
      }
      let response = await axios.post(
        branchService_dotnet + "Topup/get-topup-warehouse-list",
        {
          branchID: localStorage.getItem("Branch_BranchID"),
          dateFrom: this.search_date_from,
          dateTo: this.search_date_to,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.showdataTable = response.data.topupFcs;
        this.search_date_from = response.data.dateFrom;
        this.search_date_to = response.data.dateTo;
        this.loading = false;
      }
    },

    detete_topup(topup_id) {
      Swal.fire({
        icon: "warning",
        title: "ต้องการลบ ใช่หรือไม่!!",
        // text: this.myKeyword.textD1[this.language]+' '+ name +' '+ this.myKeyword.textD2[this.language],
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.get(
            branchService_dotnet + "Topup/delete-topup-warehouse?ID=" + topup_id,
            {
              headers: this.header_token,
            }
          );
          if (response.data.status == "success") {
            this.AlertSuccess();
            await this.search_topup_history();
          }
        }
      });
    },

    async upload_file() {
      var form_data = new FormData();
      form_data.append("file", this.myFile);
      form_data.append("bucket", "branch/topup");
      let UploadPicResponse = await axios.post(
        generalService_dotnet + "General/upload-file-with-bucket",
        form_data,
        { headers: this.header_token }
      );
      if (UploadPicResponse.status == 200) {
        this.myFile_insert = UploadPicResponse.data.file_path;
      }
    },

    open_dialog_topup(isFullscreen) {
      this.topup_dialog = true;
      this.isFullscreen = isFullscreen;
      if (isFullscreen == true) {
        this.size_percent_for_mobile = "95%";
      } else {
        this.size_percent_for_mobile = "45%";
      }
    },

    async createTopupOrder() {
      if (this.BranchBankAccountID == null) {
        this.BankValid = true;
        this.BankValidText = "กรุณาเลือกธนาคาร !!";
        this.$refs.BranchBankAccountID.focus();
      } else if (this.Amount == null || this.$refs.form.validate() == false) {
        this.AmountValid = true;
        this.AmountValidText = "กรุณากรอกยอดที่โอน !!";
        this.$refs.form.validate();
        this.$refs.Amount.focus();
      } else if (this.date_transfer == null) {
        console.log("กรุณากรอก วันที่");
      } else if (this.time == null) {
        console.log("กรุณากรอก เวลา");
      } else {
        this.TransferDate = this.date_transfer + " " + this.time + ":00";
        this.loading = true;
        if (this.myFile != null) {
          await this.upload_file();
        }
        let response = await axios.post(
          branchService_dotnet + "Topup/add-topup-warehouse",
          {
            branchID: localStorage.getItem("Branch_BranchID"),
            amount: this.Amount,
            transferDate: this.TransferDate,
            bankID: this.BranchBankAccountID,
            fileUrl: this.myFile_insert,
            remark: this.Remark,
          },
          { headers: this.header_token }
        );
        if (response.data.status == "success") {
          this.clear_data();
          this.data_and_time_onload();
          this.search_date_from = null;
          this.search_date_to = null;
          await this.search_topup_history();
          this.topup_dialog = false;
          this.AlertSuccess();
          this.resetValidForm();
          this.loading = false;
          this.topupDialog = false;
        }
      }
    },

    clear_data() {
      this.Amount = null;
      this.date_transfer = null;
      this.time = null;
      this.TransferDate = null;
      this.BranchBankAccountID = this.bank_list[0]["id"];
      this.File_url = null;
      this.myFile_insert = null;
      this.Remark = null;
      this.path_name = null;
      this.myFile_insert = null;
      this.myFile = null;
    },

    async export_to_excel() {
      this.loading = true;
      let response = await axios.post(adminService + "finance/export_to_excel_topup_fc", {
        data_export: this.showdataTable,
      });
      if (response.data.status == "Success") {
        var filename = response.data.this_path;
        window.open(adminService + "finance/download/" + filename, "_blank");
      } else {
        this.AlertError();
      }
      this.loading = false;
    },
    format_number,
    format_price,
    FormatTopup,
    AlertSuccess,
    AlertError,
    set_format_date_time,
  },
};
</script>

<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}

.mobile-none {
  display: ;
}

.pc-none {
  display: none;
}

@media (max-width: 599px) {
  .mobile-none {
    display: none;
  }

  .pc-none {
    display: ;
  }
}
</style>
